
  
  .lcontainer {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: 500px;
    width: 100%;
    margin: auto;
  }
  .lcontainer > form {
    width: 70%;
    border: 1px solid #dfdfdf;
    padding: 20px;
    border-radius: 5px;
    background: #fff;
  }
  pre {
    width: 70%;
    color: #fff;
    font-size: larger;
  }

  p {
    color: red;
  }
  
 .eyeicon{
   float: right;
   position: relative;
   left: 40px;
 }