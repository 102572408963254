.topbar {
  width: 100%;
  height: 50px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
}

.topbarWrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-weight: bold;
  font-size: 30px;
  color: darkblue;
  cursor: pointer;
}

.topRight{
    display: flex;
    align-items: center;
}

.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: #555;
}

.topIconBadge{
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.topAvatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}
.signout{
  text-decoration: none;
  color: white;
}

/* ////////////////////////// */

.topbar {
  width: 100%;
  height: 60px;
  background: linear-gradient(to right, #dee1e4, #50e3c2); 
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.topbarWrapper {
  height: 100%;
  padding: 0 30px; 
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-weight: bold;
  font-size: 32px; 
  color: white; 
  cursor: pointer;
  transition: color 0.3s ease; 
}

.logo:hover {
  color: #f7f7f7; 
}

.topRight {
  display: flex;
  align-items: center;
}

.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 15px; 
  color: white; 
  transition: color 0.3s ease; 
}

.topbarIconContainer:hover {
  color: #f7f7f7; 
}

.topIconBadge {
  width: 18px; 
  height: 18px;
  position: absolute;
  top: -5px;
  right: -5px; 
  background-color: #ff3b30; 
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px; 
}

.topAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid white; 
  transition: border-color 0.3s ease; 
}

.topAvatar:hover {
  border-color: #f7f7f7; 
}

.signout {
  text-decoration: none;
  color: white;
  font-weight: bold; 
}
