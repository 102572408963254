
.userTitleContainer {
    display: flex;
    justify-content: center; 
    align-items: center; 
    background-color: #f0f2f5;
    padding: 10px; 
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    margin-bottom: 10px;
  }
  
  .userTitle {
    font-size: 36px;
    font-weight: bold;
    color: #2dad2a; 
    text-transform: uppercase; 
    letter-spacing: 2px; 
    margin: 0; 
  }

  .firstrow h5{
    margin-top: 30px;
    margin-left: 40px;
}
.secondrow h5{
    margin-top: 30px;
    margin-left: 80px;
}
.thirdrow h5{
    margin-top: 30px;
    margin-left: 80px;
}
.fourthrow h5{
    margin-top: 30px;
    margin-left: 80px;
}

#letter{
    margin-top: 30px;
}

.fifthrow h5{
    margin-top: 40px;
    margin-left: 40px;
}

.sixthrow h5{
    margin-top: 30px;
    margin-left: 80px;
    top: 20px;
}

.topflex{
    display: flex;
}
.topstatus{
    margin: 40px 0 0 20px;

}
.topstatus h5{
    color: blue;
}
.routing button{
    border: none;
    outline: none;
    background: none;
    justify-content: space-around;
    font-weight: 500;
}
.routing{
    margin-left: 30%;
}
.routingsecond{
    margin-left: 40px;
}
.topcontact{
    margin: 50px 0 0 160px;
}
a{
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
.exitButton {
    position: absolute;
    top: 60px; 
    right: 40px; 
    padding: 3px 13px; 
    background-color: #f94848; 
    color: white;
    border: none; 
    border-radius: 5px;
    cursor: pointer; 
  }
  
  .exitButton:hover {
    background-color: #ff0400; 
  }
  