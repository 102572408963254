/* .sidebar {
  width: 240px;
  height: calc(100vh - 50px);
  background-color: rgb(251, 251, 255);
  position: sticky;
  top: 55px;
  
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.sidebarListItem:hover.active,.sidebarListItem:hover {
  background-color: rgb(100, 400, 400);
}

.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
} */

.sidebar {
  width: 280px;
  height: calc(100vh - 50px);
  background: linear-gradient(135deg, #2793ff, #69dac1);
  position: sticky;
  top: 55px;
  color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebarWrapper {
  padding: 20px;
  color: white;
}

.sidebarMenu {
  margin-bottom: 20px;
}

.sidebarTitle {
  font-size: 16px;
  color: #e0e0e0;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.sidebarList {
  list-style: none;
  padding: 0;
}

.sidebarListItem {
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 12px;
  margin-bottom: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.sidebarListItem:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateX(5px);
}

.sidebarListItem.active {
  background-color: rgba(255, 255, 255, 0.3);
  transform: translateX(5px);
}

.sidebarIcon {
  margin-right: 10px;
  font-size: 22px;
  color: white;
}

.link {
  text-decoration: none;
  color: inherit;
  transition: color 0.2s;
}

.link:hover {
  color: #f1f1f1;
}

.sidebarListItem {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.sidebarListItem:hover.active, .sidebarListItem:hover {
  background-color: #5a33f0;
  transform: translateX(8px);
}

/* /////// */



.table th, .table td {
  word-wrap: break-word; 
}

.table th, .table td {
  text-align: center;
}

.table td {
  max-width: 150px; 
}



