.popup-box {
  position: fixed;
  background: #00000010;
  width: 102%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 40%;
  max-height: 80vh;
  margin: 0 auto;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  top: 1%;
  transform: translateY(20%); 
}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: absolute; 
  top: 10px; 
  right: 10px;
  background: #f11111;
  color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 25px; 
  text-align: center;
  border: 1px solid #999;
  font-size: 16px;
}
