.newUserButton1{
    position: relative;
    left: 75%;
    top: 15px;
      
  }
   #item{
    /* padding: 20px 10px 15px; */
    display: block;
  
  }
  
  #first{
    height: 50px;
    width: 100%;
    
  }
  
  #firsthead{
    margin: 0 0 0 2%;
  }

  .payslipmastertop h3{
    margin-left: 45px;

  }

  #toptable{
    display: grid;
    grid-template-columns: 20% 10%;
  }