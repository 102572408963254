.invoicehead {
    margin: 20px 0 0 0;
}

.input {
    display: flex;
    justify-content: space-between;
}
.inputs{
    flex: 5px;
}
.selects{
    width: 65%;
    height: 45%;
}
/* .selectss{
    width: 65%;
    height: 45%;
} */
i{
    margin-top: 20px;
}
.firstbutton{
    position: relative;
    left: 75%;
    bottom: 20px;
  
    
}
.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    padding: 20px;
  } 

  /* #comp{
      margin-left: 120px;
      margin-top: 40px;
  } */

  .textfiel{
    border: 5px solid red;
  }
  

  .invoice-number {
    text-align: center;
    background-color: rgb(41, 124, 240);
    color: white; /* Optional: Makes the text color white */
    padding: 20px; /* Optional: Adds some padding for better presentation */
    border-radius: 5px; /* Optional: Adds rounded corners */
  }
  