 .formfields{
    width: 70%;
}
.formfield{
    margin-left: 50px;
}
.formcontrol{
    width: 70%;
}
#newtop{
    width: 70%;
    height: 8vh;
}