.userlist {

  margin-left: 1px;
  width: 1000px;
  cursor: pointer;
}

.userListUser {
  display: flex;
  align-items: center;
}

.userListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.userListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.userListDelete{
    color: red;
    cursor: pointer;
}
.userAddButton {
 /*  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  float: left;
  font-size: 16px;
  padding: 10px 0; */
  float: right;
}


.userAddButton1{
  /* margin: auto;
  display: flex;
  padding: 10px 0; */
}
