/* General Styles */
.newUserForm {
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .formfield {
    margin-bottom: 20px;
  }
  
  .newUserItem {
    margin-bottom: 15px;
  }
  
  /* Select and Dropdown Styles */
  .formcontrol {
    width: 100%;
    border-radius: 4px;
    /* border: 1px solid #ccc; */
    /* padding: 10px; */
    font-size: 16px;
  }
  
  .formcontrol .dropdown {
    height: 100%;
  }
  
  /* TextField Styles */
  .formfields {
    width: 100%;
    margin-top: 8px;
  }
  
  /* Buttons */
  .newUserButton {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .newUserButton:hover {
    background-color: #0056b3;
  }
  
  /* Loss of Pay Section */
  .lossofpay {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
  
  .lossofpay h5 {
    margin-right: 10px;
  }
  
  .MinimizeIcon,
  .AddIcon {
    font-size: 20px;
    cursor: pointer;
    margin: 0 5px;
  }
  
  /* Dropdown Styles */
  .newUserItem h5 {
    font-size: 18px;
    margin-bottom: 8px;
  }
  
  .select-dropdown {
    height: 100%;
  }
  
  /* Date Picker Styles */
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .react-datepicker__input-container input {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .react-datepicker__header {
    background-color: #007bff;
    color: white;
  }

  
  .lossofpay{
    margin-top: 20px;
}

.AddIcon{
    margin-left: 20px;
    border: 0px solid red;
}

.MinimizeIcon{
    margin-left: 20px;
    margin-right: 20px;
    border: 0px solid red;
}

#datepickers{
    width: 70%;
    padding: 8px 20px;
    border-radius: 5px;
}

#newtop{
    width: 100%;
    height: 10vh;
} 
