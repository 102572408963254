.dtHorizontalExampleWrapper {
    max-width: 900px;
    margin: 0 auto;
    }
    #dtHorizontalExample th, td {
    white-space: nowrap;
    }
    
/*     table.dataTable thead .sorting:after,
    table.dataTable thead .sorting:before,
    table.dataTable thead .sorting_asc:after,
    table.dataTable thead .sorting_asc:before,
    table.dataTable thead .sorting_asc_disabled:after,
    table.dataTable thead .sorting_asc_disabled:before,
    table.dataTable thead .sorting_desc:after,
    table.dataTable thead .sorting_desc:before,
    table.dataTable thead .sorting_desc_disabled:after,
    table.dataTable thead .sorting_desc_disabled:before {
    bottom: .5em;
    } */
#userAddButton{
    float: right;
}

.employeetop{
    display: flex;
    position: relative;
    top: 22px;
    margin-left: 35px;
    justify-content: space-around;
   
}
#userAddButtons{
    float: right;
    position: relative;
    top: 20px;
   
}
.employeesearchbox{
    width: 25%;
}
.employeetop h4{
    position: relative;
    right: 95px;
    top: 20px;
}